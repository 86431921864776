export default function hideMenu(){
    let zero = document.querySelector(".page-zero");

    if(zero){
        let language = document.querySelectorAll(".group-language");
        let content = document.querySelector(".header-content");
        let mobile = document.querySelector(".main-header__mobile-controls");

        content.style.display = "none";
        mobile.style.display = "none";

        language.forEach(item => {
            item.style.display = "none";
        })
    }
}
