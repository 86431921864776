export default function menuDekstop() {
    if(document.querySelector(".desktop-menu")) {
        let btnBurger = document.querySelector(".burger-icon-container");
        let menu = document.querySelector(".menu-content");
        let closeMenu = document.querySelector(".close-menu");

        btnBurger.addEventListener("click", () => {
            menu.classList.add("is-active");
        });

        closeMenu.addEventListener("click", () => {
            menu.classList.remove("is-active");
        });
    }
}