export default function closeMenu() {
    let btnCloseMenu = document.querySelectorAll(".close-menu");
    let mainMenuMobile = document.querySelector(".main-menu-mobile");
    
    btnCloseMenu.forEach(btn => {
        btn.addEventListener("click", () => {
            document.querySelector("body").classList.remove("mobile-menu-is-open");
            mainMenuMobile.classList.remove("is-active");
        });
    });
}